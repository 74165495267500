import * as Sentry from '@sentry/nextjs'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'
import * as React from 'react'
import type { FallbackProps } from 'react-error-boundary'

import Button from '@/components/core/Button'

const ClientError: React.FCC<FallbackProps> = ({
  resetErrorBoundary,
  error,
}) => {
  const t = useTranslations()

  React.useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <>
      <NextSeo title="Erro inesperado" />

      <span className="mx-auto flex h-screen max-w-md flex-col justify-center space-y-8 px-6">
        <h1 className=" text-title-md font-bold text-primary-500">Ops...</h1>
        <div className="space-y-4">
          <p>{t('common.error-title1')}</p>
          <p>{t('common.error-title2')}</p>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <Button
            href="https://wa.me/5516982510114"
            className="button--primary"
          >
            {t('common.error-button')}
          </Button>
          <Link href="/" onClick={resetErrorBoundary} className="text-center">
            {t('common.error-link')}
          </Link>
        </div>
      </span>
    </>
  )
}

export default ClientError
